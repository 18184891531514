/* padding-top */
.pt4 {
  padding-top: 4px;
}
.pt8 {
  padding-top:8px;
}
.pt16 {
  padding-top: 16px;
}

/* padding-bottom */
.pb4 {
  padding-bottom: 4px;
}
.pb8 {
  padding-bottom: 8px;
}
.pb16 {
  padding-bottom: 16px;
}

/* margin-top */
.mt4 {
  margin-top: 4px;
}
.mt8 {
  margin-top: 8px;
}
.mt16 {
  margin-top: 16px;
}