@import './align.scss';

.family{
  padding-left: 15px;
  .parent{
    display: flex;
    align-items: center;
    :not(:first-child) {
      margin-left: 5px;
    }
    padding-bottom: 5px;
  }
  .children{}
}

.person-node-view{
  &:hover {
    cursor: pointer
  }
  border: 2px solid rgba(0,0,0,.2);
  padding: 5px;
  display: inline-block;
  &.female {
    background-color: #fdaed8;
  }
  &.male{
    background-color: #a4ecff;
  }
  &.blood-line{
    text-decoration: underline;
  }
  &.deceased {
    color: #888;
  }
  &.divorced {
    border: 2px dashed rgba(0,0,0,.2);
  }
}

.person-full-view{
  >div{
    margin-top: 8px;
  }
  .h1 {
    font-size: 30px;
    font-weight: 700;
  }
  .h3 {
    font-size: 20px;
    font-weight: 700;
  }
  .h5 {
    font-size: 16px;
    font-weight: 400;
  }
}

.collapsable {
  .collapsable-title {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  .collapsable-content {}
}

.container {
  padding: 16px;
}